export default [
  {
    header: 'Tools',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Tasks',
        route: 'mlf-tools-tasks',
        icon: 'CheckCircleIcon',
      },
      {
        title: 'Support Requests',
        route: 'mlf-tools-support-requests',
        icon: 'LifeBuoyIcon',
      },
    ],
  },
]
