export default [
  {
    header: 'System',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Access Control',
        route: 'mlf-system-access-control',
        icon: 'ShieldIcon',
      },
      {
        title: 'Env Settings',
        route: 'mlf-system-env-settings',
        icon: 'SettingsIcon',
      },
      {
        title: 'Cron Jobs',
        route: 'mlf-system-cron-jobs',
        icon: 'RepeatIcon',
      },
      {
        title: 'Logging',
        icon: 'FileTextIcon',
        children: [
          {
            title: 'FrontEnd',
            route: 'mlf-system-logging-frontend',
            icon: 'MonitorIcon',
          },
          {
            title: 'Backend',
            route: 'mlf-system-logging-backend',
            icon: 'CommandIcon',
          },
          {
            title: 'Mailing',
            route: 'mlf-system-logging-mailing',
            icon: 'MailIcon',
          },
          {
            title: 'Commits',
            route: 'mlf-system-logging-commits',
            icon: 'GitCommitIcon',
          },
        ],
      },
      {
        title: 'Developer',
        icon: 'CoffeeIcon',
        children: [
          {
            title: 'Databases',
            route: 'mlf-system-developer-databases',
            icon: 'DatabaseIcon',
          },
          {
            title: 'Servers',
            route: 'mlf-system-developer-servers',
            icon: 'ServerIcon',
          },
          {
            title: 'Storage',
            route: 'mlf-system-developer-storage',
            icon: 'HardDriveIcon',
          },
          {
            title: 'Code',
            route: 'mlf-system-developer-code',
            icon: 'CodeIcon',
          },
          {
            title: 'SSH',
            route: 'mlf-system-developer-ssh',
            icon: 'TerminalIcon',
          },
          {
            title: 'Services',
            route: 'mlf-system-developer-services',
            icon: 'CloudIcon',
          },
          {
            title: 'GitLab',
            href: 'https://gitlab.com/mlf1',
            icon: 'GitlabIcon',
          },
        ],
      },
    ],
  },
]
