export default [
  {
    header: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'View',
        icon: 'ListIcon',
        children: [
          {
            title: 'All',
            route: 'mlf-users-all',
            icon: 'GridIcon',
          },
          {
            title: 'Creators',
            route: 'mlf-users-creators',
            icon: 'VideoIcon',
          },
          {
            title: 'Viewers',
            route: 'mlf-users-viewers',
            icon: 'EyeIcon',
          },
          {
            title: 'Top Earners',
            route: 'mlf-users-top-earners',
            icon: 'AwardIcon',
          },
          {
            title: 'Deleted',
            route: 'mlf-users-deleted',
            icon: 'UserMinusIcon',
          },
        ],
      },
      {
        title: 'Applications',
        icon: 'LayersIcon',
        children: [
          {
            title: 'Pending',
            route: 'mlf-users-applications-pending',
            icon: 'UserPlusIcon',
          },
          {
            title: 'Approved',
            route: 'mlf-users-applications-approved',
            icon: 'UserCheckIcon',
          },
          {
            title: 'Rejected',
            route: 'mlf-users-applications-rejected',
            icon: 'UserXIcon',
          },
        ],
      },
      {
        title: 'Payout Requests',
        icon: 'DollarSignIcon',
        children: [
          {
            title: 'Approved',
            route: 'mlf-users-payouts-approved',
            icon: 'SmileIcon',
          },
          {
            title: 'Pending',
            route: 'mlf-users-payouts-pending',
            icon: 'MehIcon',
          },
          {
            title: 'Rejected',
            route: 'mlf-users-payouts-rejected',
            icon: 'FrownIcon',
          },
        ],
      },
    ],
  },
]
