export default [
  {
    header: 'Dataverse',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Alert Types',
        route: 'mlf-dataverse-alert-types',
        icon: 'AlertTriangleIcon',
      },
      {
        title: 'Media Types',
        route: 'mlf-dataverse-media-types',
        icon: 'FilmIcon',
      },
      {
        title: 'Notification Types',
        route: 'mlf-dataverse-notification-types',
        icon: 'InboxIcon',
      },
      {
        title: 'Profile Links',
        route: 'mlf-dataverse-profile-links',
        icon: 'Link2Icon',
      },
      {
        title: 'Purchase Types',
        route: 'mlf-dataverse-purchase-types',
        icon: 'GiftIcon',
      },
      {
        title: 'User Role Types',
        route: 'mlf-dataverse-user-role-types',
        icon: 'SlidersIcon',
      },
      {
        title: 'Alert Recipients',
        route: 'mlf-dataverse-alert-recipients',
        icon: 'AtSignIcon',
      },
      {
        title: 'Default Tags',
        route: 'mlf-dataverse-default-tags',
        icon: 'TagIcon',
      },
    ],
  },
]
