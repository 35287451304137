export default [
  {
    header: 'Extras',
    icon: 'MoreHorizontalIcon',
    children: [
      {
        title: 'Docs',
        route: 'mlf-wip-page',
        icon: 'BookIcon',
      },
      {
        title: 'MLF',
        icon: 'PackageIcon',
        children: [
          {
            title: 'Home',
            href: 'https://www.myluckyfans.com',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'Admin',
            href: 'https://admin.myluckyfans.com',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'Commands',
            href: 'https://commands.myluckyfans.com',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'Filesystem',
            href: 'https://upload.myluckyfans.com',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'Vault',
            href: 'https://vault.myluckyfans.com',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'Other',
            route: 'mlf-wip-page',
            icon: 'LinkIcon',
          },
        ],
      },
      {
        title: 'Vuexy',
        icon: 'PackageIcon',
        children: [
          {
            title: 'Home',
            href: 'https://pixinvent.com',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'Vuexy Docs',
            href: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/guide/#how-to-use-docs',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'BVue Docs',
            href: 'https://bootstrap-vue.org',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'BVue CheatSheet',
            href: 'https://bootstrap-cheatsheet.themeselection.com/',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'Repo',
            href: 'https://pixinvent.com/gitlab-access-provider-for-envato',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'Demo',
            href: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1',
            icon: 'ExternalLinkIcon',
          },
          {
            title: 'Icons',
            href: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/ui/feather',
            icon: 'ExternalLinkIcon',
          },
        ],
      },
    ],
  },
]
